import React, {useState} from 'react';
import {Link} from "react-router-dom";
import "./Workouts.css";
import ReactModal from "../../common/ui/react-modal/ReactModal";
import WorkoutForm from "./WorkoutForm";
import DefReset from "../../common/models/DefReset";
import WorkoutService from "../services/WorkoutService";

const WorkoutSubMenu = (props) => {
    const { selection, workout } = props;
    
    const classes = {};
    classes[selection || ""] = "selected";
    
    const basePath = workout?.parentWorkout?.id ? 
        "/workouts/" + workout.parentWorkout.id + "/workouts/" + workout.id :
        "/workouts/" + workout.id;
    
    const openFormAsync = async (e) => {
        const dialog = new ReactModal();
        const formTitle = "Edit: " + workout?.name;

        const workoutOptions = { status: "0" };
        const options = {
            buttons: [
                (<span style={{marginRight: "auto"}}>
                    <select defaultValue={workoutOptions.status} id={"new-workout-status"} onChange={(e) => {
                        workoutOptions.status = e.target.value;
                    }}>
                        <option value={"0"}>Draft</option>
                        <option value={"1"}>Published</option>
                    </select>
                </span>)
            ],
            appendButtons: true,
        };        const form = (<WorkoutForm defaultValue={workout} options={workoutOptions} title={formTitle} />);

        const rsp = await dialog.openFormDialogAsync(form, async(e) => {
            //Week A: Rest and Recover
            DefReset.stopEvent(e);
            
            const json = typeof workoutOptions.getData === "function" ?
                workoutOptions.getData() :
                null;

            json.status = parseInt(workoutOptions.status) || json.status || 0;
            await WorkoutService.instance.saveWorkoutAsync(json, workout.id);
            
            return true;
        }, options);
        
        console.log("Form opened.");
        const element = document.getElementById("workout-name");
        if (typeof element?.focus === "function") element.focus();
    };

    return (<div className={"workout-menu"}>
        <ul className={"sub-menu"}>
            <li className={classes["details"] || ""}><Link to={basePath}>Details</Link></li>
            <li className={classes["exercises"] || ""}><Link to={basePath + "/exercises"}>Exercises</Link></li>
            <li className={classes["media"] || ""}><Link to={basePath + "/media"}>Media</Link></li>
            <li className={classes["edit"] || ""}><a onClick={openFormAsync}>Edit</a></li>
        </ul>
    </div>);
};

export default WorkoutSubMenu;