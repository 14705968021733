import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import FuelMasterScreen from "./FuelMasterScreen";
import MealSubMenu from "../../components/fuel/ui/MealSubMenu";
import MediaUploader from "../../components/media/ui/MediaUploader";
import DefButton from "../../components/common/ui/elements/DefButton";
import DefReset from "../../components/common/models/DefReset";
import MealService from "../../components/fuel/services/MealService";
import DropTarget from "../../components/common/ui/DropTarget";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons";
import HttpService from "../../components/common/services/HttpService";

const MealJsonUploadScreen = (props) => {
    const {} = props;
    const { mealId:recipeId } = useParams();
    const uploadOptions = useState({ clearOnComplete: true })[0];
    const [screenState, setScreenState] = useState({
        id: 0,
        recipe: null,
        editId: null,
        status: 0,
        isSorting: false,
    });

    const recipe = screenState.recipe || {};
    const uploadKey = recipe?.id;
    const onImageClicked = (e) => {};

    const getRecipeAsync = async (force = false, postExtras = null) => {
        if (!!recipe?.id && recipe.id.length > 30 && force !== true)
            return recipe;

        const m = await MealService.instance.getMealAsync(recipeId);

        if (!!m?.id) {
            console.log("Got recipe good: ", m);

            if (!postExtras) postExtras = {};

            const newState = {
                ...screenState,
                recipe: m,
                editId: null, ...postExtras,
            };

            setScreenState(newState);

            return m;
        }

        console.error("Failed to get recipe: ", m);
        return recipe;
    };

    const toHtmlElements = (text) => {
        const lines = (text || "").trim().split("\n");
        return lines.map((item, index) => (<p key={"line-" + index}>{item}</p>));
    };
    
    const onJsonFileDrop = (fileModels) => {
        if (!screenState.recipe?.id) return false;

        // const json = { description: "Add Description" };
        // const recipe = screenState.recipe;
        //
        // if (!Array.isArray(recipe.media)) recipe.media = [];
        //
        // const newState = {...screenState};
        // newState.recipe = recipe;
        //
        // for(let i = 0; i < fileModels.length; i++) {
        //     const fileModel = fileModels[i];
        //     const json = { url: fileModel.filePath, id: "new-" + (Math.random() * 99999999).toString(16).toLowerCase() };
        //
        //     recipe.media.push(new ImageModel(json));
        // }

        //setScreenState(newState);
    }

    const onJsonUploaded = async (mealModel) => {
        const newState = {...screenState};
        newState.recipe = screenState.recipe;
        newState.recipe.media = mealModel.media;
        newState.recipe.foods = mealModel.foods;
        
        setScreenState(newState);
        //await getRecipeAsync(true, { scrollToId: imageId, editId: imageId });
    };
    
    const combineJsonElement = (sendId, e) => {
        DefReset.stopEvent(e);
        
        const recId = sendId - 1;
        const media = screenState.recipe.media || [];
        if (sendId >= media.length) return null;
        if (recId >= media.length) return null;
        
        const endText = screenState.recipe.media[sendId].description;
        media[recId].description += "\n\n" + endText;
        
        media.splice(sendId, 1);
        const newState = {...screenState};
        newState.recipe.media = media;
        
        setScreenState(newState);
    }

    useEffect(() => {
        let _ = getRecipeAsync();
    }, [])
    
    const sortIcon = null;
    const elementClasses = { hover: "", dragOver: "", hasFiles: "" };
    
    
    const ingredientElements = recipe?.ingredients?.map((ingredient, index) => {
        return (<span key={"ingredient-" + index} className={"ingredient"}>
            <span>{ingredient.name}</span>
        </span>);
    });
    
    const elements = recipe?.media?.map((mediaItem, index) => {
        const itemId = "direction-item-" + index; //mediaItem.id;
        
        const itemBody = (screenState.editId === mediaItem.id || screenState.editId === index.toString()) ? (<div slot={mediaItem.id}>
                <div><textarea style={{height: "120px"}} defaultValue={mediaItem.description} id={"image-description-" + mediaItem.id}></textarea></div>
                <div style={{padding: "12px 0", alignItems: "center", width: "100%"}} className={"flex"}>
                    <span><DefButton onClick={(e) => DefReset.stopEvent(e)}>Save Directions</DefButton></span>
                    <span style={{marginLeft: "auto"}}><a>Delete</a></span>
                    <span style={{marginLeft: "16px"}}><a>Cancel</a></span>
                </div>
            </div>
        ) : (<p className={"text-editable"} onClick={(e) => DefReset.stopEvent(e)}>
            {toHtmlElements(mediaItem.description || "Click to edit")}
        </p>);
        
        if (!!mediaItem.url && !mediaItem.url.startsWith("http"))
            mediaItem.url = "";

        const inputId = "direction-url-" + index;
        const imageElement = !!mediaItem.url ? (<div><img className={"cooking-direction-image"} src={mediaItem.url}/></div>) : null;
        const inputUrlElement = !mediaItem.url ? (<div><input type={"text"} id={inputId} style={{width: "100%"}} placeholder={"Image Url"}/></div>) : null;
        
        const onUrlDrop = async (fileItems, otherItems, payload, e) => {
            console.warn(otherItems);
            let imageUrl = null;
            
            for(let i = 0; i < otherItems.length; i++) {
                const dataTransferItem = otherItems[i];
                console.log(dataTransferItem);
                
                imageUrl = await new Promise((resolve, reject) => {
                    dataTransferItem?.getAsString((textValue) => {
                        console.log("Good A: ", textValue);
                        
                        if (!textValue || typeof textValue !== "string" || !textValue.toLowerCase().startsWith("http"))
                            return resolve(textValue + "");
                            
                        return resolve(textValue || "");
                    });
                });
                
                if (!!imageUrl) {
                    //mediaItem.image/
                    mediaItem.url = imageUrl;
                    console.log("Parse Image Url: ", imageUrl);
                    const newState = {...screenState};
                    newState.recipe = recipe;
                    setScreenState(newState);
                    break;
                } else {
                    console.error("Invalid Url");
                }
            }
        };
        
        const mergeElement = index > 0 ? (<div onClick={(e) => combineJsonElement(index, e)} className={"right"} style={{float: "right"}}><FontAwesomeIcon icon={faCaretUp}/></div>) : null;
        return (<DropTarget id={itemId} key={"media-" + itemId} onDrop={onUrlDrop} classes={elementClasses} className={"fader json-direction none"}>
            {mergeElement}
            {itemBody}
            {imageElement}
            {inputUrlElement}
        </DropTarget>);
    });
    
    const activityElement = (<div className={"center-uploading"}>
        <span className={"flex-label"}>
            <span className={""}>Uploading...</span>
            <label className={"spinner"}></label>
        </span>
    </div>);

    const sortClassName = screenState.isSorting ? "sorting" : "";
    
    return (<FuelMasterScreen selection={"meals"} title={"Meal Recipe"}>
        <div className={"pad recipe-directions " + sortClassName}>
            <h2 className={""}>
                <span>Recipe: {recipe?.name}</span>
                <span></span>
            </h2>

            <MealSubMenu meal={recipe} selection={"json-web"} trailing={sortIcon} />

            <div className={"un-sort"}>
                <MediaUploader
                    options={uploadOptions}
                    activity={activityElement}
                    onClick={onImageClicked}
                    onComplete={onJsonUploaded} 
                    uploadUrl={"/api/fuel/web-recipe-upload"}
                    className={"directions-image-dropper"} count={1} onDrop={onJsonFileDrop}>
                    <div className={"non-working"}>Drop a Large Image Here to Add a Step</div>
                    <div className={"working-only spinner"}>
                        <label></label>
                    </div>
                </MediaUploader>
            </div>
            
            <div>{ingredientElements}</div>

            {elements}

            <div>
                <pre>{JSON.stringify(recipe, null, 4)}</pre>
            </div>
        </div>
    </FuelMasterScreen>);
};

export default MealJsonUploadScreen;
