import React, {useEffect, useRef, useState} from 'react';
import ChallengeMasterScreen from "./ChallengeMasterScreen";
import ChallengeService from "../../components/challenges/services/ChallengeService";
import {useParams} from "react-router-dom";
import HabitService from "../../components/habits/services/HabitService";
import DefReset from "../../components/common/models/DefReset";
import DateTime from "../../components/common/ui/DateTime";
import MediaUploader from "../../components/media/ui/MediaUploader";
import ReactModal from "../../components/common/ui/react-modal/ReactModal";
import MediaForm from "../../components/media/ui/MediaForm";
import DefButton from "../../components/common/ui/elements/DefButton";
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ChallengeEditScreen = (props) => {
    const {} = props;
    const { challengeId} = useParams();
    
    const [viewState, setViewState] = useState({});
    const [challenge, setChallenge] = useState(ChallengeService.instance.challengeMap[challengeId] || null);

    const nameRef = useRef();
    const descriptionRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const issuedByRef = useRef();
    const habitRef = useRef();
    const statusRef = useRef();
    const weekNumberRef = useRef();
    
    const buttonId = "save-challenge-button";
    const uploadKey = "challenge-" + challengeId;
    const videoUploadKey = "challenge-video-" + challengeId;
    
    const onChallenge = (ch) => {
        if (!!ch?.id) {
            if (HabitService.instance.habits.length === 0) {
                HabitService.instance.getHabitsAsync().then((hs) => {
                    setChallenge(ch);
                });
            } else {
                setChallenge(ch);
            }
        }
    };
    
    const createJson = () => {
        let status = parseInt(statusRef.current?.value || "0") || 0;
        if (isNaN(status)) status = 0;
        
        const weekNumber = parseInt(weekNumberRef.current?.value || "1") || 1
        
        return {
            description: descriptionRef.current?.value || null,
            start_date: startDateRef.current?.value || null,
            issued_by: issuedByRef.current?.value || null,
            end_date: endDateRef.current?.value || null,
            habit_id: habitRef.current?.value || null,
            name: nameRef.current?.value || null,
            week_number: weekNumber,
            status: status,
            image_id: null,
            video_id: null,
        };
    };

    const getImageId = async () => {
        console.warn("Getting imageId...");
        const imageId = await MediaUploader.getImageIdAsync(uploadKey);

        console.log("Good: ", imageId);
        console.log("Upload Key: ", uploadKey);

        return imageId || challenge?.imageId || null;
    };
    
    const getVideoId = async () => {
        console.warn("Getting videoId...");
        const videoId = await MediaUploader.getImageIdAsync(videoUploadKey);

        console.log("Good Video: ", videoId);
        console.log("Upload Key: ", videoUploadKey);

        return videoId || challenge?.videoId || null;
    };
    
    const handleError = (ex) => {
        const message = ex?.response?.data?.message || ex?.message || ex?.toString() || "An unknown error occurred.";
        console.error("Error on Save: " + message);
        const dialog = new ReactModal();
        dialog.openAlertDialogAsync(message, { title: "Error", iconClassName: "error", icon: faExclamationTriangle });
    };

    const saveChallengeAsync = async (e) => {
        DefReset.stopEvent(e);
        
        const json = createJson();
        json.image_id = await getImageId();
        json.video_id = await getVideoId();
        
        console.log(JSON.stringify(json, null, 4));
        
        const ch = await ChallengeService.instance.saveChallengeAsync(json, challenge?.id).catch((ex) => handleError(ex));
        
        if (!ch?.id) return;
        
        ChallengeService.instance.getChallengeAsync(ch.id);

        const dialog = new ReactModal();
        await dialog.openAlertDialogAsync("Challenge saved successfully", { title: "#GOOD", iconClassName: "success", icon: faCheckCircle });

        return true;
    };
    
    const onImageClicked = () => {
        //
    };

    const onDrop = async (imageFiles) => {
        MediaUploader.options[uploadKey] = 1;
    };
    
    const onVideoDropped = async (videoFiles) => {
        MediaUploader.options[videoUploadKey] = 1;
    };
    
    const onMediaUploaded = (mediaModels) => {
        const imageModels = mediaModels.filter((mediaItem) => mediaItem?.content_type?.startsWith("image/"));
        const videoModel = mediaModels.filter((mediaItem) => mediaItem?.content_type?.startsWith("video/"));
        
        if (imageModels.length > 0) {
            MediaUploader.options[uploadKey] = imageModels[0];
        }
        
        if (videoModel.length > 0) {
            MediaUploader.options[videoUploadKey] = videoModel[0];
        }
    };

    const onVideoClicked = async (fileModel, index, e) => {
        DefReset.stopEvent(e);
        console.log("Click me: ", index);
        
        const options = {};
        const dialog = new ReactModal();
        
        const mediaForm = (<MediaForm defaultValue={challenge?.video.toMediaModel()} options={options} />);
        await dialog.openFormDialogAsync(mediaForm, async (e) => {
            //
        }, options);
        
        return false;
    };

    const onVideoDrop = (fileModels) => {
        //
    };

    useEffect(() => {
        //
    }, []);
    
    const habitOptions = HabitService.instance.habits.map((h, i) => {
        return (<option key={"habit-" + h.id} value={h.id}>{h.name}</option>);
    });

    const img = challenge?.image?.url ? (<img alt={""} className={"image128"} src={challenge.image.url} />) : (<>No Image<br/>Available</>);
    const vid = challenge?.video?.url ? (<video onClick={(e) => onVideoClicked(null, -1, e)} autoPlay={false} preload={"on"} src={challenge.video.url} playsInline={true} controls />) : (<>No Video<br/>Available</>);
    
    const uploadUrl = (!!challenge?.id ? "/api/challenge/" + challenge?.id + "/media?overwrite-video=1&overwrite-image=1" : "/api/media/upload");
    
    const imageElement = (<MediaUploader onClick={onImageClicked} onComplete={onMediaUploaded} uploadUrl={uploadUrl} className={"image-dropper"} count={1} size={128} onDrop={onDrop}>
        {img}
    </MediaUploader>);

    const videoElement = (<MediaUploader onClick={onVideoClicked} onComplete={onMediaUploaded} uploadUrl={uploadUrl} className={"video-dropper"} count={1} size={128} onDrop={onDrop}>
        {vid}
    </MediaUploader>);
    
    const statusElement = !!challenge?.id ? (<select defaultValue={challenge?.status} ref={statusRef} id={"status"}>
        <option value={"0"}>Draft</option>
        <option value={"1"}>Published</option>
    </select>) : null;
    
    const formElements = !!challenge ? (<>
        <div className={"flex"} style={{alignItems: "flex-end", marginBottom: "24px"}}>
            <div className={"image"}>{imageElement}</div>
            <div className={"image"}>{videoElement}</div>
            <div className={"fields"} style={{flexGrow: "4"}}>
                <div className={"form-group"}>
                    <label>Name:</label>
                    <input type={"text"} ref={nameRef} defaultValue={challenge?.name} />
                </div>

                <div className={"form-group triple"}>
                    <div>
                        <label>Start Date</label>
                        <input type={"date"} ref={startDateRef} id={"start-date"}
                               defaultValue={DateTime.formatDate(challenge?.startDate, "yyyy-MM-dd")}/>
                    </div>
                    <div>
                        <label>End Date</label>
                        <input type={"date"} ref={endDateRef} id={"end-date"}
                               defaultValue={DateTime.formatDate(challenge?.endDate, "yyyy-MM-dd")}/>
                    </div>
                    <div>
                        <label>Week Number</label>
                        <input type={"number"} ref={weekNumberRef} id={"week-number"}
                               defaultValue={challenge.weekNumber}/>
                    </div>
                </div>
            </div>
        </div>

        <div className={"form-group"}>
            <label>Description:</label>
            <textarea className={"medium"} ref={descriptionRef} defaultValue={challenge?.description}></textarea>
        </div>

        <div className={"form-group"}>
            <label>Associated Habit (Optional):</label>
            <select defaultValue={challenge?.habitId} ref={habitRef} className={"wide"}>
                <option key={"empty-habit"} value={""}>- None -</option>
                {habitOptions}
            </select>
        </div>

        <div className={"form-buttons flex"}>
            <span style={{marginRight: "auto"}}>{statusElement}</span>
            <span><DefButton id={buttonId} onClick={saveChallengeAsync}>Save</DefButton></span>
        </div>
    </>) : null;

    return (<ChallengeMasterScreen onChallenge={onChallenge} selection={"edit"}>
        <div className={"pad constrain"}>
            <div>
                <h2>{challenge?.name}</h2>
                <p className={"p"}>{challenge?.description}</p>
            </div>

            <div className={"form"}>
                { formElements }
            </div>
        </div>
    </ChallengeMasterScreen>);

};

export default ChallengeEditScreen;
