import React, {useRef} from 'react';
import DefButton from "../../common/ui/elements/DefButton";
import DefReset from "../../common/models/DefReset";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileImage, faTrashAlt, faUpload, faXmark} from "@fortawesome/free-solid-svg-icons";
import {Tooltip } from "@mui/material";
import MediaUploader from "../../media/ui/MediaUploader";
import ImageModel from "../../media/models/ImageModel";

const IngredientLineItem = (props) => {
    const { isEditing = false, name:foodName = null, onDelete, index, isNew = false, subtitle = "", value:ingredient, onChange, onBlur, onSelect } = props;
    const nameRef = useRef();
    
    const onEditChange = (ingredient, e) => {
        if (typeof onChange !== "function") return;
        onChange(ingredient, e);
    }

    const onDeletePress = (ingredient, e) => {
        if (typeof onDelete !== "function") return;
        onDelete(ingredient, e);
    }
    
    const onEditBlur = (ingredient, e) => {
        if (typeof onBlur !== "function") return;
        onBlur(ingredient, e);
    };
    
    const setAsNew = (e) => {
        DefReset.stopEvent(e);
        ingredient.foodId = null;
        ingredient.id = null;
        ingredient.foodMatches = [];
        ingredient.name = nameRef.current?.value || foodName || ingredient.name;
        
        onEditChange(ingredient, e);
    };
    
    const onFoodImageDropped = (files, otherItems, a, b) => {
        console.log("Files: ", files);
        console.log("Other Items: ", otherItems);
        console.log("A: ", a);
        console.log("B: ", b);
        
        if (!ingredient.image) ingredient.image = new ImageModel({ })
        ingredient.image.url = files[0].filePath;
        onEditChange(ingredient, { target: { id: "image", value: ingredient.image } });
    };
    
    const onCompleted = (items) => {
        onEditChange(ingredient, null);
    };
    
    const selectIngredient = (ingredientModel, e) => {
        if (typeof onSelect !== "function") return;
        
        if (!ingredient.foodId) {
            onSelect(index, e);
            return;
        }
        
        onSelect(ingredientModel, e);
    };
    
    const foodUploadUrl = "/api/food/" + ingredient.foodId + "/upload";
    const imageElement = !!ingredient.image?.url ?
        (<img src={ingredient.image.url} alt={ingredient.name} className={"thumb"} />) :
        !!ingredient.foodId ? (<MediaUploader id={"food-upload-" + ingredient.foodId} onDrop={onFoodImageDropped} classes={{ hasFiles: "icon-upload", className: "icon-upload" }} className={"icon-upload"} uploadUrl={foodUploadUrl}>
            <FontAwesomeIcon icon={faFileImage} />
        </MediaUploader>) : (<div style={{ width: "48px", height: "48px" }}></div>);

    const cn = isNew === true ? " is-new-food" : "";
    const itemBody = isEditing ?
        (<span className={"details form form-item flex"} style={{width: "100%"}}>
                <span style={{width: "80%"}}>
                    <span className={"line-item-form"}>
                        <span>
                            <input
                                id={"amount"}
                                defaultValue={ingredient.amount}
                                onChange={(e) => onEditChange(ingredient, e)}
                                type={"number"}
                                max={100}
                                min={0}
                                step={0.1}
                                style={{width: "70px"}} />
                        </span>
                        <span>
                            <select onChange={(e) => onEditChange(ingredient, e)} defaultValue={ingredient.amountUnits} id={"amountUnits"}>
                                <option value={4}>Cups</option>
                                <option value={7}>Tsp</option>
                                <option value={5}>Tbsp</option>
                                <option value={2}>Oz</option>
                                <option value={0}>Count</option>
                            </select>
                        </span>
                        
                        <span style={{width: "100%"}}>
                            <Tooltip title={subtitle} arrow placement={"top"}>
                                <input ref={nameRef} type={"text"} placeholder={foodName || ingredient.name} defaultValue={foodName || ingredient.name} onChange={(e) => onEditChange(ingredient, e)} id={"name"}/>
                            </Tooltip>
                        </span>
                    </span>
                    
                    <span className={""}>
                        <span>
                            <textarea
                                id={"cookingDirections"}
                                defaultValue={ingredient.cookingDirections}
                                onChange={(e) => onEditChange(ingredient, e)}></textarea>
                        </span>
                    </span>
                </span>

                <span className={"div"}>
                    <span className={"small-icons-row right"}>
                        <Tooltip title={"Delete This Ingredient"} placement={"top"} arrow>
                            <a onClick={(e) => onDeletePress(ingredient, e)}>
                                <FontAwesomeIcon icon={faTrashAlt}/>
                            </a>
                        </Tooltip>
                        
                        <Tooltip title={"Cancel"} placement={"top"} arrow>
                            <a onClick={(e) => selectIngredient(null, e)}>
                                <FontAwesomeIcon icon={faXmark}/>
                            </a>
                        </Tooltip>
                        
                    </span>
                    <DefButton onClick={(e) => onEditBlur(ingredient, e)}>Save</DefButton>
                </span>
            </span>) :
        (<span className={""}>
            <h3>{ingredient.amount} {ingredient.amountUnitsName} {foodName || ingredient.name}</h3>
            <span className={"cooking-directions"}>{ingredient.cookingDirections}</span>
        </span>);

    return (<div key={"ingredient-" + ingredient.id} className={"line-item " + cn} onClick={(e) => selectIngredient(ingredient, e)}>
        <div className={"image-item"}>
            <span>{imageElement}</span>
            {itemBody}
        </div>
    </div>);
};

export default IngredientLineItem;