import React, {useEffect, useRef, useState} from 'react';
import FuelMasterScreen from "./FuelMasterScreen";
import MealService from "../../components/fuel/services/MealService";
import {useParams} from "react-router-dom";
import DefButton from "../../components/common/ui/elements/DefButton";
import MediaUploader from "../../components/media/ui/MediaUploader";
import {Navigate} from "react-router-dom";
import MealSubMenu from "../../components/fuel/ui/MealSubMenu";
import SearchFilter from "../../components/common/ui/search-filter/SearchFilter";
import IngredientListSelector from "../../components/fuel/ui/IngredientListSelector";
import MealFoodModel from "../../components/fuel/models/MealFoodModel";
import DefReset from "../../components/common/models/DefReset";
import DropTarget from "../../components/common/ui/DropTarget";
import HttpService from "../../components/common/services/HttpService";
import FoodModel from "../../components/fuel/models/FoodModel";
import IngredientLineItem from "../../components/fuel/ui/IngredientLineItem";

const MealRecipeDetailsScreen = (props) => {
    const {} = props;
    const { mealId:recipeId } = useParams();
    const [screenState, setScreenState] = useState({ 
        id: 0,
        recipe: null,
        editId: null,
        status: 0,
        matches: [],
    });
    const nameRef = useRef();
    const descriptionRef = useRef();
    const recipe = screenState.recipe || {};
    const uploadKey = recipe?.id || "new";
    
    const setRecipe = (recipe) => {
        setScreenState({ ...screenState, recipe: recipe });
    };
    
    const getRecipeAsync = async (force = false) => {
        if (!!recipe?.id && recipe.id.length > 30 && force !== true)
            return recipe;

        const m = await MealService.instance.getMealAsync(recipeId);

        if (!!m?.id) {
            console.log("Got recipe good: ", m);
            setRecipe(m);
            return m;
        }

        console.error("Failed to get recipe: ", m);
        return recipe;
    };
    
    const onFoodSelected = (food) => {
        console.warn("Food is selected: ", food);
        
        const foods = screenState.recipe.foods || [];
        const mf = new MealFoodModel(food.toJson());
        mf.amount = 1.0;
        mf.image = food.image;
        mf.id = !!food.id ? food.id : "new-" + (Math.random() * 99999999).toString(16).toLowerCase();
        mf.foodId = food.id;
        mf.cookingDirections = food.cookingDirections || "";
        mf.description = food.description || "";
        mf.status = 0;
        
        foods.push(mf);
        
        const newState = { ...screenState };
        newState.recipe = screenState.recipe;
        newState.recipe.foods = foods;
        
        setScreenState(newState);
    };

    const onImageClicked = (fileModel, index) => {
        delete MediaUploader.options[uploadKey];
        //
        return true;
    };

    const saveAllAsync = async (e) => {
        DefReset.stopEvent(e);
        
        const jsonArray = screenState.recipe.foods.map((ingredient, index) => {

            return {
                name: ingredient.name,
                description: ingredient.description,
                cooking_directions: ingredient.cookingDirections,
                amount_units: parseInt(ingredient.amountUnits + "") || 0,
                amount: parseFloat(ingredient.amount + "") || 1.0,
                meal_id: recipeId,
                image_id: null,
                sorting: index,
                food_id: ingredient.foodId || ingredient.id,
            };
        });

        await MealService.instance.saveMealFoodAsync(jsonArray, recipeId);
        await getRecipeAsync(true);
    };
    
    const saveIngredientAsync = async (index = -1) => {
        const ingredient = screenState.saveIngredient || null;
        screenState.saveIngredient = null;
        screenState.saveIndex = null;
        
        if (!ingredient?.name) return;
        
        const json = {
            image_id: null,
            name: ingredient.name,
            description: ingredient.description,
            cooking_directions: ingredient.cookingDirections,
            amount_units: parseInt(ingredient.amountUnits + "") || 0,
            amount: parseFloat(ingredient.amount + "") || 1.0,
            meal_id: recipeId,
            food_id: ingredient.foodId || ingredient.id,
        };

        const rsp = await MealService.instance.saveMealFoodAsync(json, recipeId);
        delete screenState.saveIngredient;
        
        if (index >= 0) {
            const recipe = screenState.recipe;
            if (recipe.foods.length > index)
                recipe.foods[index] = rsp;
            
            setScreenState({ ...screenState, recipe: recipe });
        }
        
        return rsp;
    };
    
    const onEditBlur = (ingredient, index, e) => {
        const newState = { ...screenState };
        newState.saveIngredient = ingredient;
        newState.editId = null;
        newState.saveIndex = index;
        
        console.log("OnBlur: ", JSON.stringify(ingredient, null, 4));
        setScreenState(newState);
    }
    
    const onEditChange = (item, index, e) => {
        const fieldId = e?.target?.id || null;
        const needsReplacement = index >= 0 && index < screenState.recipe.foods?.length;
        
        if (!fieldId) {
            console.error("No FieldId. e: ", e);
            console.log(JSON.stringify(item, null, 4));
            
            if (needsReplacement) {
                screenState.recipe.foods[index] = item;
                setScreenState({ ...screenState, recipe: screenState.recipe });
            }
            return;
        }

        item[fieldId] = e?.target?.value;
        if (fieldId === "amountUnits") {
            item.amountUnitsName = e?.target?.selectedOptions[0]?.text || e?.target?.selectedOptions[0]?.innerText || "x";
        }
        
        console.log(JSON.stringify(item, null, 4));
    };
    
    const onDelete = (ingredient, index, e) => {
        DefReset.stopEvent(e);
        console.warn("Id: ", ingredient.id);
        console.warn("FoodId: ", ingredient.foodId);
        console.log(JSON.stringify(ingredient, null, 4));
    };
    
    const onDrop = async (fileModels) => {
        console.log("File Models: ", fileModels);
        
        const files = Array.from(fileModels).map((fileModel) => fileModel.file || fileModel);
        const path = "/api/food-json-upload";
        const rsp = await HttpService.instance.uploadAsync(path, files, null);
        const jsonArray = Array.isArray(rsp?.data) ? rsp.data : [];
        
        const items = jsonArray.map((json, index) => {
            const value = new MealFoodModel(json);
            value.foodMatches = MealFoodModel.fromJsonArray(json.match_foods);
            
            if (value.foodId === "00000000-0000-0000-0000-000000000000")
                value.foodId = null;
            
            if (value.id === "00000000-0000-0000-0000-000000000000")
                value.id = null;
            
            return value;
        });

        const currentFoods = screenState.recipe.foods || [];
        const foodIds = currentFoods.map((f) => f.foodId);
        const newItems = items.filter((item) => !foodIds.includes(item.id || item.foodId));
        const foods = [...currentFoods, ...newItems];
        
        const newState = { ...screenState };
        newState.recipe = screenState.recipe;
        newState.recipe.foods = foods;

        setScreenState(newState);
    };
    
    const selectIngredient = (ingredient, e) => {
        DefReset.stopEvent(e);
        
        const newState = { ...screenState };
        
        if (typeof ingredient === "number") {
            const recipe = screenState.recipe;
            recipe.foods.splice(ingredient, 1);
            newState.recipe = recipe;
            ingredient = null;
        } else {
            newState.editId = ingredient?.id || null;
        }

        setScreenState(newState);
    };

    useEffect(() => {
        if (!!screenState.saveIngredient?.name) {
            let _ = saveIngredientAsync(screenState.saveIndex);
        }
        
    }, [screenState]);
    
    useEffect(() => {
        let _ = getRecipeAsync(true);
    }, []);

    if (typeof recipe === "string") {
        return <Navigate to={recipe} />;
    }

    const emptyImageMessage =  (<span style={{fontSize: "12px"}}>Drag/Drop or<br/>Click Here<br/>Here to Upload<br/>A Thumbnail</span>);
    const isLoaded = !!recipe?.id;
    const contentElement = null;
    const imageUrl = recipe?.image?.url || null;
    const foods = recipe?.foods || [];
    const ingredients = [...foods];
    let showSaveAllButton = false;
    
    const elements = ingredients.map((item, index) => {
        const hasMatches = item.foodMatches?.length > 0;
        const hasArray = Array.isArray(item.foodMatches);
        const ingredient = hasMatches ? item.foodMatches[0] : item;
        const keyName = "item-" + index;
        const isEditing = screenState.editId === ingredient.id;
        const existingIngredientName = hasMatches ? ingredient.name : null;
        const newIngredientName = item.name;
        
        if (hasMatches) showSaveAllButton = true;
        
        //ingredient.name = item.name;
        ingredient.sorting = index;
        if (hasMatches && !ingredient.foodId)
            ingredient.foodId = item.foodId;

        return (<IngredientLineItem 
            isNew={!hasMatches && hasArray}
            subtitle={item.name}
            key={keyName} 
            name={item.name}
            index={index}
            value={ingredient} 
            isEditing={isEditing} 
            onDelete={(item, e) => onDelete(item, index, e)}
            onBlur={(item, e) => onEditBlur(item, index, e)}
            onSelect={(item, e) => selectIngredient(item, e)}
            onChange={(item, e) => onEditChange(item, index, e)} />);
    });
    
    const saveAllButton = showSaveAllButton ? (<DefButton onClick={saveAllAsync}>Save All</DefButton>) : null;
    const jsonFileDropperElement = screenState.recipe?.foods?.length > 0 ? 
        null :
        (<DropTarget id={uploadKey} onDrop={onDrop} classes={"drop-target"} className={"fader"}>
            Drop LLM JSON File
        </DropTarget>);
    
    return (<FuelMasterScreen selection={"meals"} title={"Meal Recipe"}>
        <div className={"pad"}>
            <h2 className={""}>
                <span>Recipe: {recipe?.name}</span>
                <span></span>
            </h2>
            
            <MealSubMenu meal={recipe} selection={"recipes"}/>

            <p>{recipe?.description}</p>
            <div>{saveAllButton}</div>
            
            <div className={""}>
                <IngredientListSelector onSelected={onFoodSelected} />
            </div>
            
            <div className={"form"}>
                {jsonFileDropperElement}
                
                {elements}
            </div>
            <div>
                <pre>{JSON.stringify(recipe, null, 4)}</pre>
            </div>
        </div>
    </FuelMasterScreen>);

};

export default MealRecipeDetailsScreen;
