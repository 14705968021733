import React, {useEffect, useRef, useState} from 'react';
import WorkoutsMasterScreen from "./WorkoutMasterScreen";
import {Link, useParams} from "react-router-dom";
import WorkoutSubMenu from "../../components/workouts/ui/WorkoutSubMenu";
import DefReset from "../../components/common/models/DefReset";
import WorkoutService from "../../components/workouts/services/WorkoutService";
import ReactModal from "../../components/common/ui/react-modal/ReactModal";
import WorkoutForm from "../../components/workouts/ui/WorkoutForm";
import LoadingBox from "../../components/common/ui/LoadingBox";
import AddNewButton from "../../components/common/ui/AddNewButton";
import {faDumbbell, faWeight} from "@fortawesome/free-solid-svg-icons";

const WorkoutDetailsScreen = (props) => {
    const { workoutId:queryWorkoutId } = useParams();
    const [workout, setWorkout] = useState(queryWorkoutId || null);
    const workoutId = workout?.id || queryWorkoutId;
    const [viewState, setViewState] = useState({ workoutJson: null });
    
    const nameRef = useRef();
    const descriptionRef = useRef();
    const durationRef = useRef();
    const durationUnitsRef = useRef();
    const workoutTypeRef = useRef();

    const onWorkout = (w, parentWorkout) => {
        w.parentWorkout = parentWorkout;
        setWorkout(w);
    };
    
    const updateWorkoutAsync = async (e, fieldId) => {
        DefReset.stopEvent(e);

        const value = e.target?.value || null;
        
        if (!value) {
            return;
        }
        
        workout[fieldId] = value;
        const json = workout.toJson();
        console.log(JSON.stringify(json, null, 4));
        
        await WorkoutService.instance.saveWorkoutAsync(json, workout.id);
        
        toggleViewState(e, fieldId);        
    };
    
    const toggleViewState = (e, fieldId) => {
        const newState = {...viewState};
        const value = !(viewState[fieldId] === true);
        
        newState[fieldId] = value;
        
        if (value === true) newState.focusId = fieldId;
        else newState.focusId = null;
        
        setViewState(newState);
    };

    const onNewWorkoutChange = (json, e) => {
        console.log(json);

        const newState = {...viewState};
        newState.workoutJson = json;
        
        setViewState(newState);
    };

    const openNewForm = async (e) => {
        DefReset.stopEvent(e);
        const dialog = new ReactModal();
        const options = {};
        
        const form = (<WorkoutForm options={options} onChange={onNewWorkoutChange} id={workout.id} title={"New Workout"} />);
        
        const rsp = await dialog.openFormDialogAsync(form, async(e) => {
            //Week A: Rest and Recover
            DefReset.stopEvent(e);
            console.log(viewState.workoutJson);
            
            const json = viewState.workoutJson || options.getData();
            json.parent_workout_id = workoutId;
            
            await WorkoutService.instance.saveWorkoutAsync(json);
            
            return true;
        }, options);
    };
    
    useEffect(() => {
        if (typeof viewState.focusId === "string" && !!viewState.focusId) {
            const e = document.getElementById(viewState.focusId);
            
            if (typeof e?.focus === "function") {
                const ix = setTimeout(() => {
                    clearTimeout(ix);
                    e.focus();
                }, 100);
            }
        }
    }, [viewState]);
    
    useEffect(() => {
        //
    }, []);
    
    const subWorkoutElements = workout?.workouts?.map((subWorkout, index) => {
        return (<Link to={"/workouts/" + workoutId + "/workouts/" + subWorkout.id} key={"sw-" + index} className={"sub-workout"}>
            <h3>{subWorkout.name}</h3>
            <p>{subWorkout.description}</p>
        </Link>);
    });
    
    const subWorkouts = (subWorkoutElements?.length > 0) ?
        (<div>
            <div className={"sub-workouts"}>
                <h3>Workout Schedule</h3>
                <div className={"workouts"}>{subWorkoutElements}</div>
            </div>
        </div>) : null;
    
    const navWorkoutElements = !!workout?.parentWorkout?.id ? 
        (<><Link to={"/workouts/" + workout.parentWorkout.id}>{workout.parentWorkout?.name}</Link> <span className={"bullet"}>&#10095;</span> </>) : 
        null;
    
    const descriptionElement =!!viewState?.description ?
        (<div><textarea defaultValue={workout?.description} id={"description"} className={"inline"} ref={descriptionRef} onBlur={(e) => updateWorkoutAsync(e, "description")}></textarea></div>) :
        (<p onClick={(e) => toggleViewState(e, "description") } className={"p"}>{workout?.description}</p>);
    
    const loadingElement = !workout?.id ? (<LoadingBox>Loading Workout...</LoadingBox>) : null;
    const addLink = !!workout?.id && !workout.parentWorkoutId ?
        (<AddNewButton icon={faDumbbell} onClick={openNewForm}>Add Sub Workout</AddNewButton>) :
        <span></span>;
    
    return (<WorkoutsMasterScreen onWorkout={onWorkout} selection={"details"}>
        <div className={"pad form"}>
            <h3 className={"nav with-button"}>
                {navWorkoutElements}
                <span>{workout?.name}</span>
                {addLink}
            </h3>

            {descriptionElement}
            {subWorkouts}
            {loadingElement}
            
            <pre>
                {JSON.stringify(workout, null, 4)}
            </pre>
        </div>
    </WorkoutsMasterScreen>);
};

export default WorkoutDetailsScreen;
