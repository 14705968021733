import React, {useEffect, useState} from 'react';
import DropTarget from "../../common/ui/DropTarget";
import DefReset from "../../common/models/DefReset";
import FileUploadModel from "../../common/models/FileUploadModel";
import HttpService from "../../common/services/HttpService";

const MediaUploader = (props) => {
    const { onDrop, id, className, activity, onUrlsDropped, urlEndpoint, children, uploadKey, uploadUrl, onVideoThumbnail, onClick, onComplete, onError, size:imageSize, options, count:imageCount } = props;
    const objectId = useState(MediaUploader.createObjectId(id))[0];
    const fileElementId = "input-" + objectId;
    const [screenState, setScreenState] = useState({ state: "idle", fileModels: [] });
    const fileModels = screenState.fileModels;
    const elementId = useState("dropper-" + (id || (Math.random() * 99999999).toFixed(0)))[0];
    
    const size = imageSize || options?.size || null;
    const count = imageCount || options?.count || 1;
    
    const setFileModels = (files) => {
        if (!Array.isArray(files)) files = [];
        
        if (files.length === 0) DefReset.removeClassName(elementId, "dropped");

        setScreenState({ ...screenState, fileModels: files });
    };
    
    const uploadMediaAsync = async (files) => {
        if (typeof uploadUrl !== "string" || uploadUrl.length < 1) {
            const message = "Missing Upload Url";
            console.error(message);
            return false;
        }
        
        const maxCount = getMaxCount();
        
        if (maxCount > 0 && maxCount < files.length) files = files.filter((f, i) => i < maxCount);
        
        files = files.map((fm) => fm.file || fm);
        
        const results = await HttpService.instance.uploadAsync(uploadUrl, files, {}).catch((ex) => {
            if (typeof onError === "function") {
                onError(ex);
            } else {
                console.error(ex);
            }
            
            return null;
        });
        
        console.log("Upload Process Complete.");
        if (!results) console.error("Failed.");
        
        if (!!results && typeof onComplete === "function") {
            console.log("Successful.");
            console.log(results);
            
            let x = onComplete(results?.data);
            x = (typeof x?.then === "function") ? await x : x;
            
            if (x === false || options?.clearOnComplete === true) {
                console.log("Clearing uploaded files.");
                setFileModels([]);
            }
        }
    }
    
    const onFilesPastedAsync = async (pastedItems) => {
        const targetId = MediaUploader.targetIds.length > 0 ? MediaUploader.targetIds[MediaUploader.targetIds.length - 1] : null;
        
        if (!!targetId && targetId !== objectId) {
            console.warn("TargetId skip: ", targetId, " ?? ", objectId);
            console.log(MediaUploader.targetIds);
            return;
        }
        
        const loadedFiles = [];
        const itemCount = pastedItems.length;

        const pastedFiles = await new Promise((resolve, reject) => {
            console.log("Pasted Items Count [" + id + "]: ", pastedItems.length);
            
            if (pastedItems.length <= 0) {
                console.warn("No files dropped");
                resolve([]);

                return;
            }

            for(let i = 0; i < pastedItems.length; i++) {
                const f = new FileUploadModel(pastedItems[i], "", (fileModel) => {
                    console.log(fileModel);
                    loadedFiles.push(fileModel);

                    if (loadedFiles.length >= itemCount) {
                        resolve(loadedFiles);
                    }
                });
            }
        });

        let _;
        const rsp = typeof onDrop === "function" ? onDrop(pastedFiles) : null;
        if (rsp !== false) _ = uploadMediaAsync(pastedFiles);

        const finalFiles = typeof count === "number" && count > 0 && count < pastedFiles.length ? pastedFiles.filter((f, i) => i < count) : pastedFiles;
        
        setFileModels(finalFiles);
    };

    const onUrlsDroppedAsync = async (urls) => {
        if (!Array.isArray(urls) || !urls) return null;

        const rsp = (typeof onUrlsDropped === "function") ?
            onUrlsDropped(urls) : 
            null;
        
        if (rsp === false) return [];
        if (!urlEndpoint) return rsp ?? [];
        
        const payload = { urls: urls };
        const results = await HttpService.instance.postAsync(urlEndpoint, payload).catch((ex) => {
            console.error(ex);
            return [];
        });
        
        return results?.data || [];
    };
    
    const onFileDroppedAsync = async (fileItems, otherItems, payload, e) => {
        DefReset.stopEvent(e);
        DefReset.setClassName(elementId, "dropped");
        
        const loadedFiles = [];
        const itemCount = fileItems.length;
        const imageUrls = await MediaUploader.getDataItemUrlsAsync(otherItems);
        
        if (imageUrls.length > 0) {
            console.log("Urls Dropped: ", imageUrls);
            await onUrlsDroppedAsync(imageUrls);
        }
        
        if (itemCount === 0) {
            console.warn("No Files to Process on Drop.");
            return [];
        }

        console.log("Processing Files: ", fileItems);
        const files = await new Promise((resolve, reject) => {
            console.log("File Count: ", fileItems.length);
            
            if (fileItems.length <= 0) {
                console.warn("No files dropped");
                resolve([]);
                return;
            }

            for(let i = 0; i < fileItems.length; i++) {
                const f = new FileUploadModel(fileItems[i], "", (fileModel) => {
                    console.log(fileModel);
                    loadedFiles.push(fileModel);

                    if (loadedFiles.length >= itemCount) {
                        resolve(loadedFiles);
                    }
                });
            }
        });

        let _;
        const rsp = typeof onDrop === "function" ? onDrop(files) : null;
        
        if (rsp !== false) {
            DefReset.setClassName(elementId, "uploading");
            _ = uploadMediaAsync(files).then(() => {
                DefReset.removeClassName(elementId, "uploading");
            });
        }
        
        const finalFiles = typeof count === "number" && count > 0 && count < files.length ? files.filter((f, i) => i < count) : files;
        
        setFileModels(finalFiles);
    };
    
    const openFilePicker = (e) => {
        //DefReset.stopEvent(e);
        
        const inputElement = document.getElementById(fileElementId);
        
        if (!inputElement) {
            console.error("Input Element not found");
            return;
        }
        
        console.log("Click: ", e);
        inputElement.click(e);
    };
    
    const getMaxCount = (defaultValue = -1) => {
        return typeof count === "number" && count > 0 ? count : defaultValue;
    };
    
    const onItemClick = async (fileModel, index, e) => {
        DefReset.stopEvent(e);
        const x = (typeof onClick === "function") ? onClick(fileModel, index) : null;
        const rsp = (typeof x?.then === "function") ? await x : x;
        
        if (rsp === false) return;
        
        const newFiles = [...fileModels];
        
        if (rsp === true) {
            newFiles.splice(index, 1);
        }
        
        setFileModels(newFiles);
    };
    
    const onVideoLoaded = (e, x) => {
        console.log("OnVideo Loaded: ", e);
        console.log(" > X: ", x);
    };

    const style = typeof size === "number" && size > 0 ? { width: size + "px", height: size + "px", objectFit: "cover"} : {};
    
    const createMediaElement = (f) => {
        if (!f.filePath) return null;
        
        if (typeof f.contentType === "string" && f.contentType.startsWith("image/")) {
            const imageElement = (<img src={f.filePath} className={"image-upload-preview"} alt={"Preview"} style={style} />);
            
            return (!!activity)  ? (<div className={"dropper-with-activity"}>
                {imageElement}
                <div className={"dropper-activity"}>{activity}</div>
            </div>) : imageElement;
        }
            
        
        console.log("Created Element");
        return (<video onLoad={(e) => onVideoLoaded(e, "onLoad")} onLoadedData={(e) => onVideoLoaded(e, "onLoadedData")} src={f.filePath} className={"video-upload-preview"} alt={"Preview"} style={style} />);
    }

    const setControls = () => {
        MediaUploader.targetIds.push(objectId);
        
        return () => {
            MediaUploader.targetIds.pop();
        };
    }
    
    useEffect(() => {
        const x = setControls();
        
        //
        
        return x;
    }, []);
    
    const maxCount = getMaxCount();
    
    const body = fileModels.length > 0 ?
        (maxCount > 0 ? fileModels.filter((x, n) => n < maxCount) : fileModels).map((f, i) => (<div key={"file-" + i.toString()} onClick={(e) => onItemClick(f, i, e)}>
            {createMediaElement(f)}
        </div>)) : children;

    const cn = typeof className === "string" ? className : "";
    const stateClassName = "state-" + screenState.state;
    
    return (<DropTarget id={elementId} onClick={openFilePicker} className={cn + " can-work " + stateClassName} onPaste={(pastedItems) => onFilesPastedAsync(pastedItems)} onDrop={(fileItems, otherItems, payload, e) => onFileDroppedAsync(fileItems, otherItems, payload, e)}>
        {body}
        <input style={{display: "none"}} id={fileElementId} type={"file"} multiple={true} onChange={(e) => onFileDroppedAsync(e.target.files, [], {}, e)} />
    </DropTarget>);
};

MediaUploader.getDataItemUrlsAsync = async (dataItems) => {
    const imageUrls = [];
    
    for(let i = 0; i < dataItems.length; i++) {
        const dataTransferItem = dataItems[i];
        console.log(dataTransferItem);
        if (dataTransferItem.kind === "file") continue;

        const imageUrl = await new Promise((resolve, reject) => {
            console.log("Promising [" + i + "]: ", dataTransferItem);
            dataTransferItem?.getAsString((textValue) => {
                console.log("Good A: ", textValue);

                if (!textValue || typeof textValue !== "string" || !textValue.toLowerCase().startsWith("http"))
                    return resolve(textValue + "");

                return resolve(textValue || "");
            });
        });

        if (!!imageUrl) imageUrls.push(imageUrl);
    }
    
    return imageUrls;
};

MediaUploader.targetIds = [];
MediaUploader.options = {};
MediaUploader.getImageIdAsync = async (uploadKey, waitIntervalMs = 500) => {
    if (typeof waitIntervalMs !== "number") waitIntervalMs = 500;
    
    while (!!MediaUploader.options[uploadKey]) {
        const img = MediaUploader.options[uploadKey];
        if (!!img?.url && !!img?.id && img.id.length > 30 && img.url.startsWith("http")) {
            console.log("Good to go [" + uploadKey + "]: " + img.id);
            return img?.id;
        }
        
        if (typeof img === "string" && img.length === 36)
            return img;

        await new Promise((resolve) => setTimeout(resolve, waitIntervalMs));
    }

    return null;
};

MediaUploader.createObjectId = (id = null) => {
    if (!id || typeof id !== "string" || MediaUploader.targetIds.includes[id])
        id = "media-uploader-" + (Math.random() * 999999).toString(16).toUpperCase();

    MediaUploader.targetIds.push(id);
    return id;
};

export default MediaUploader;
