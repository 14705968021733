import React, {useEffect, useRef, useState} from 'react';
import LearnMasterScreen from "./LearnMasterScreen";
import MediaUploader from "../../components/media/ui/MediaUploader";
import DefReset from "../../components/common/models/DefReset";
import ArticleService from "../../components/learn/services/ArticleService";
import {Link, Navigate, useParams} from "react-router-dom";
import DefButton from "../../components/common/ui/elements/DefButton";
import ReactModal from '../../components/common/ui/react-modal/ReactModal';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ArticleModel from "../../components/learn/models/ArticleModel";

const ArticleDetailsScreen = (props) => {
    const {} = props;
    const {articleId} = useParams();
    const [article, setArticle] = useState(null);
    const [editedModel, setEditedModel] = useState({});
    
    const statusRef = useRef();
    const titleRef = useRef();
    const urlRef = useRef();
    const webUrlRef = useRef();
    const contentRef = useRef();
    
    const uploadKey = article?.id || "new";
    
    const onArticle = (article) => {
        if (!!article?.id) {
            article.videoElement = {};
            setArticle(article)
        }
    };
    
    const handleError = (ex) => {
        const message = ex?.response?.data?.message || ex?.message || ex?.toString() || "An unknown error occurred.";
        console.error("Error on Save: " + message);
        setEditedModel({...editedModel, error: message, save: false });
    };
    
    const createJson = () => {
        const json = !!article?.id ? article?.toJson() : {};
        
        json.title = titleRef.current?.value || json.title || "";
        json.url = urlRef.current?.value || json.url || "";
        json.web_url = webUrlRef.current?.value || json.webUrl || "";
        json.content = contentRef.current?.value || json.content || null;
        json.content_type = getContentType(json.url);

        const newStatus = parseInt(statusRef.current.value);
        if (!isNaN(newStatus)) json.status = newStatus;
        
        return json;
    };

    const getImageId = async () => {
        console.warn("Getting imageId...");
        const imageId = await MediaUploader.getImageIdAsync(uploadKey);
        
        console.log("Good: ", imageId);
        console.log("Upload Key: ", uploadKey);
        
        return imageId || article?.imageId || null;
    };
    
    const saveArticleAsync = async (e) => {
        DefReset.stopEvent(e);
        const json = createJson();
        json.image_id = await getImageId();
        
        if (!json.image_id && json.status !== 0) {
            alert("No ImageId: " + json.status + "")
            return;
        }
        
        const x = await ArticleService.instance.saveArticleAsync(json, article?.id).catch((ex) => handleError(ex));

        console.log("NewId: " + x?.id);
        console.log(x);

        if (x === null) return;
        
        const dialog = new ReactModal();
        dialog.openAlertDialogAsync("Article saved successfully", { title: "#GOOD", iconClassName: "success", icon: faCheckCircle });

        setEditedModel({ status: x.status });
    }

    const getContentType = (filePath, defaultValue = "application/octet-stream") => {
        if (!filePath) filePath = article?.url;
        if (!filePath) return defaultValue;
        
        const url = (filePath || "").trim().toLowerCase();
        
        if (url.endsWith(".mp4")) return "video/mp4";
        if (url.endsWith(".webm")) return "video/webm";
        if (url.endsWith(".mov")) return "video/mov";

        if (url.endsWith(".png")) return "image/png";
        if (url.endsWith(".jpg")) return "image/jpg";
        if (url.endsWith(".jpeg")) return "image/jpeg";
        if (url.endsWith(".pjpeg")) return "image/jpeg";
        if (url.endsWith(".pjpg")) return "image/jpeg";
        if (url.endsWith(".gif")) return "image/gif";
        
        if (url.indexOf("youtube.com") > 0) return "youtube/video";
        if (url.indexOf("youtu.be") > 0) return "youtube/video";
        if (url.indexOf("vimeo.com") > 0) return "video/vimeo";

        const tokens = url.split("/");
        const fileName = tokens[tokens.length - 1];

        if (fileName.indexOf(".") < 0) {
            // No extension
            if (url.startsWith("http")) return "text/html";
            return defaultValue;
        }
        
        return article?.contentType || defaultValue;
    };
    
    const onDrop = (fileModels) => {
        MediaUploader.options[uploadKey] = 1;
    };
    
    const onImageUploaded = (mediaModels) => {
        const imageModels = mediaModels.filter((mediaItem) => mediaItem?.content_type?.startsWith("image/"));
        const videoModel = mediaModels.filter((mediaItem) => mediaItem?.content_type?.startsWith("video/"));
        
        if (imageModels.length > 0) {
            console.log("Good Article Image");

            
            const imageModel = imageModels[0];

            if (!!article) {
                article.image = imageModel;
                article.imageId = imageModel.id;
            }

            MediaUploader.options[uploadKey] = imageModel;
            //
            // const edit = {...editedModel};
            //
            // edit.image = imageModels[0];
            // edit.save = MediaUploader.options[uploadKey] === 2;
            //
            // if (edit.save) console.log("Image has been save-buffered. Should upload after re-render...");
            // else delete MediaUploader.options[uploadKey];

            //setEditedModel(article ?? new ArticleModel({ image: imageModel, image_id: imageModel.id }));
            console.log("Good.");
            setArticle(article ?? new ArticleModel({ image: imageModel, image_id: imageModel.id }));
        } else {
            console.error("No Image Models came back from onImageUploaded");
            console.log(mediaModels);
        }
    };
    
    const deleteArticle = (e) => {
        DefReset.stopEvent(e);
        
        if (article?.id) {
            ArticleService.instance.deleteArticleAsync(article?.id).then((x) => {
                console.log("Deleted: ", x);
                setEditedModel({ redirect: "/learn" });
            }).catch((ex) => handleError(ex));
        }
    };

    const onImageClicked = (fileModel, index) => {
        delete MediaUploader.options[uploadKey];
        setEditedModel({});
        
        return true;
    };
    
    const getWebsiteMetaAsync = async (url) => {
        const meta = await ArticleService.instance.getWebsiteMetaAsync(url);
        if (!!meta) {
            const newState = {...editedModel};
            newState.title = meta.title;
            newState.description = meta.description;
            newState.imageUrl = meta.imageUrl;
            
            if (!!titleRef.current) titleRef.current.value = meta.title || titleRef.current.value;
            if (!!contentRef.current) contentRef.current.value = meta.description || contentRef.current.value;
            
            setEditedModel(newState);
        }
        
        console.log(meta);
    };
    
    const updateContentType = (e) => {
        DefReset.stopEvent(e);
        
        const fileName = e.target?.value || (typeof e?.preventDefault === "function" ? contentRef?.current?.value : "") || "";
        const contentType = getContentType(fileName);
        
        console.log("File Name: ", fileName);
        console.log("Content Type: ", contentType);
    };
    
    const onUrlBlur = (e) => {
        DefReset.stopEvent(e);
        const url = e.target?.value || "";
        
        if (url.length > 5) {
            let _ = getWebsiteMetaAsync(url);
        }
    };
    
    useEffect(() => {
        //
    }, [article]);
    
    useEffect(() => {
        if (editedModel.save === true) {
            console.log("Re-Render Good and now resuming upload...");
            delete MediaUploader.options[uploadKey];
            let _ = saveArticleAsync(true);
        }
        
    }, [editedModel]);
    
    useEffect(() => {
        //
    }, []);
    
    if (typeof editedModel?.redirect === "string" && !!editedModel.redirect) {
        return (<Navigate to={editedModel.redirect} />);
    }
    
    const isNew = articleId.length < 5;
    const isLoaded = isNew || !!article?.id;
    const emptyImageMessage =  (<span style={{fontSize: "12px"}}>Drag/Drop or<br/>Click Here<br/>Here to Upload<br/>A Thumbnail</span>);
    
    const contentType = isLoaded ? getContentType(article?.url) : "";
    const imageUrl = article?.image?.url || editedModel?.imageUrl || null;
    
    const img = imageUrl ? 
        (<img alt={"Article Thumb"} className={"image128"} src={imageUrl} />) :
        emptyImageMessage;
    
    const imageElement = isLoaded ? (<MediaUploader onClick={onImageClicked} onComplete={onImageUploaded} uploadUrl={"/api/media/upload"} className={"image-dropper"} count={1} size={128} onDrop={onDrop}>
        {img}
    </MediaUploader>) : null;
    
    const statusElement = isLoaded ? (<select defaultValue={article?.status} ref={statusRef} id={"status"}>
        <option value={"0"}>Draft</option>
        <option value={"1"}>Published</option>
    </select>) : null;
    
    const pushElement = (editedModel?.status !== -1) ? 
        (<span className={"push"}><Link to={"/settings/messaging/" + article?.id}>Send Push</Link></span>) :
        null;

    const titleElement = !!article?.id ? (<h3 className={"nav with-buttons"}>
            <span>Article: {titleRef?.current?.value || article?.title}</span>
            {pushElement}
            <span><a onClick={deleteArticle} className={"delete"} style={{fontWeight: "normal"}}>Delete</a></span>
        </h3>) :
        (articleId.length < 5 ? (<h3>New Article</h3>) : null);
    
    const contentElement = (<div className={"form-group"} style={{paddingBottom: "0"}}>
            <label>Content URL ({contentType}):</label>
            <input onChange={updateContentType} ref={urlRef} id={"url"} type={"text"} defaultValue={article?.url || ""} />
            <div className={"notes"}>Direct URL to video, photo, or YouTube Video: {article?.videoId}</div>
        </div>);
    
    return (<LearnMasterScreen onArticle={onArticle}>
        {titleElement}
        <div className={"form"}>
            <div className={"flex"} style={{alignItems: "flex-start"}}>
                <div className={"image top"} style={{marginTop: "32px"}}>{imageElement}</div>
                <div className={"fields"} style={{flexGrow: "4"}}>
                    <div className={"form-group"}>
                        <label>Article Title:</label>
                        <input ref={titleRef} id={"title"} type={"text"} defaultValue={article?.title || ""} />
                    </div>

                    {contentElement}
                </div>
            </div>

            <div className={"form-group"}>
                <label>Optional Web Url: </label>
                <input ref={webUrlRef} id={"web_url"} type={"text"} defaultValue={article?.webUrl || ""} onBlur={onUrlBlur} />
                <div className={"notes"}>
                    When a Web Url is specified, the app will display a hyperlink
                    that links out to an external mobile web browser. Otherwise, no button will appear on the article
                </div>
            </div>
            
            <div className={"form-group"}>
                <label>Content:</label>
                <textarea ref={contentRef} className={"wide tall"} id={"content"} defaultValue={article?.content || ""}></textarea>
                <div className={"notes"}>
                    Text content that will be displayed on the article details page. It can be a preview of the website (Web Url) content or
                    contain all of the content of the article
                </div>
            </div>
            
            <div className={"form-buttons flex"}>
                <span>{statusElement}</span>
                <DefButton onClick={saveArticleAsync} style={{minWidth: "100px"}} className={"button"}>Save</DefButton>
            </div>
            
            <br/><br/>
            <pre>
                {JSON.stringify(article, null, 4)}
            </pre>
        </div>
    </LearnMasterScreen>);
};

export default ArticleDetailsScreen;
