import React, {useEffect, useState} from 'react';
import CommentsList from "../../components/messaging/ui/comments/CommentsList";
import ReactModal from "../../components/common/ui/react-modal/ReactModal";
import DebugJson from "../../components/common/ui/DebugJson";
import {useParams} from "react-router-dom";
import {setOptions} from "marked";
import AdminMasterScreen from "../AdminMasterScreen";
import ModerationMasterScreen from "./ModerationMasterScreen";
import UserHabitsTable from "../../components/users/ui/UserHabitsTable";

const UserBaseHabitsScreen = (props) => {
    const {} = props;
    const { entityId } = useParams();

    const [options, setOptions] = useState({
        endDate: null,
        startDate: null,
        entityId: entityId,
        url: "/api/comment/" + ((typeof entityId === "string" && entityId.length > 30 ? entityId : "") || "all"),
    });

    useEffect(() => {
        //console.log("UseEffect Comments Screen: ", entityId);
    }, []);

    return (<ModerationMasterScreen selection={"habits"}>
        <div className={"pad"}>
            <h2>Incoming Habits</h2>
            <div className={"empty-box"} style={{}}>
                Too records to display.
            </div>
        </div>
    </ModerationMasterScreen>);
};

export default UserBaseHabitsScreen;

