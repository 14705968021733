import React, {useEffect, useRef, useState} from 'react';
import SettingsMasterScreen from "../SettingsMasterScreen";
import FeedbackService from "../../../components/messaging/services/FeedbackService";
import {Link, Navigate, Redirect, useParams} from "react-router-dom";
import MediaGallery from "../../../components/media/ui/MediaGallery";
import FeedbackModel from "../../../components/messaging/models/FeedbackModel";
import DefReset from "../../../components/common/models/DefReset";
import DateTime from "../../../components/common/ui/DateTime";
import HtmlContent from "../../../components/common/ui/HtmlContent";
import ProfilePhoto from "../../../components/users/ui/ProfilePhoto";
import DefButton from "../../../components/common/ui/elements/DefButton";
import ReactModal from "../../../components/common/ui/react-modal/ReactModal";

const FeedbackDetailsScreen = (props) => {
    const {} = props;
    const buttonId = "reply-button";
    const { feedbackId } = useParams();
    const [feedbackItem, setFeedbackItem] = useState(FeedbackService.instance.feedbacksMap[feedbackId] || null);
    const [screenState, setScreenState] = useState({ url: "", id: 0 });
    
    const messageRef = useRef();
    
    const getFeedbackItemAsync = async (force = false) => {
        const exists = !!feedbackItem?.id;
        if (exists && force !== true) return feedbackItem;
        
        const item = await FeedbackService.instance.getFeedbackItemAsync(feedbackId);
        
        if (!!item?.id) {
            let _;
            
            if (!exists) _ = FeedbackService.instance.getFeedbackItemsAsync();
            setFeedbackItem(item);
        } else {
            console.error("Failed: ", item);
        }
        
        return item;
    };
    
    const showReply = (e) => {
        DefReset.stopEvent(e);
    };

    const saveReplyAsync = async (e) => {
        DefReset.stopEvent(e);
        
        const json = { 
            message: messageRef.current?.value || null,
            device_type: 3,     // Web
            version: null,
            feedback_type: 4,   // Response
        };
        
        if (!json.message) return;
        
        const newItem = await FeedbackService.instance.saveFeedbackReplyAsync(json, feedbackId);
        messageRef.current.value = "";
        
        await getFeedbackItemAsync(true);
    };
    
    const removeElement = (itemId) => {
        const elementId = "thread-item-" + itemId;
        const element = document.getElementById(elementId);
        
        if (!!element?.parentNode) {
            element.parentNode.removeChild(element);
            return true;
        }
        
        return false;
    };
    
    const resendReplyAsync = async (e, feedbackId) => {
        DefReset.stopEvent(e);
        await FeedbackService.instance.resendFeedbackAsync(feedbackId);
    };
    
    const deleteAsync = async (e, id = null) => {
        DefReset.stopEvent(e);
        id ??= feedbackId;
        
        const redirectUrl = id === feedbackId ? "/settings/feedback" : null;
        
        if (!redirectUrl) {
            removeElement(id);
            const idx = feedbackItem?.thread.findIndex((item) => item.id === id);
            if (idx >= 0) {
                feedbackItem.thread.slice(idx, 1);
            }
        }
        
        let _ = FeedbackService.instance.deleteFeedbackAsync(id || feedbackId);
        setScreenState({ id: new Date().getTime(), url: redirectUrl });
    };
    
    const setControls = () => {
        //
    };

    const showImage = async (media, e) => {
        DefReset.stopEvent(e);
        const imageUrl = media?.url;
        const dialog = new ReactModal();
        const options = { title: "Feedback Media", width: "auto", height: "auto" };
        const body = (<div><img src={imageUrl} /></div>);
        
        await dialog.openFormDialogAsync(body, (e) => {
            //
        }, options);
    };

    useEffect(() => {
        let _ = getFeedbackItemAsync();
    }, [feedbackItem]);
    
    useEffect(() => {
        setControls();
    }, []);

    if (screenState.url) {
        return (<Navigate to={screenState.url} />);
    }

    const elements = Array.isArray(feedbackItem?.thread) ? feedbackItem.thread.map((item, index) => {
        if (!item) return null;

        //showImage(e, imageUrl)
        const user = item?.user;
        const profileImage = (<ProfilePhoto user={user} size={32} />);

        return (<div key={"feedback-item-" + index} className={"message-item details"} id={"thread-item-" + item.id}>
            <p><HtmlContent>{item.message}</HtmlContent></p>
            
            <div className={"message-item-footer"}>
                <span className={"profile-photo"}>{profileImage}</span>
                <span className={"name"}><Link to={"/people/" + item.userId}>{item.user?.name || "No Name"}</Link></span>
                <span>{item.deviceTypeName}</span>
                <span style={{marginLeft: "24px", fontSize: "13px"}} className={"send-reply"}><a onClick={(e) => resendReplyAsync(e, item.id)}>Re-Send</a></span>
                <span style={{marginRight: "12px", fontSize: "13px"}} className={"delete-reply"}><a onClick={(e) => deleteAsync(e, item.id)}>Delete</a></span>
                <span><DateTime value={item.created} time={true} /></span>
            </div>
        </div>);
    }) : null

    const user = feedbackItem?.user;
    const profileImage =  user?.id ? (<ProfilePhoto user={feedbackItem?.user} size={32} />) : null;
    
    const repliesHeader = feedbackItem?.thread?.length > 0 ? 
        (<h3 className={"def-header no-margin"} style={{paddingTop: "32px"}}>Replies ({feedbackItem.thread.length})</h3>) :
        null;
    
    const mediaElements = Array.isArray(feedbackItem?.media) ? feedbackItem.media.map((media, index) => {
        return (<div key={"media-item-" + index} className={"media-item"}>
            <a className={"media-item"} onClick={(e) => showImage(media, e)}><img className={"media-thumb"} src={media.url} alt={media.name} /></a>
        </div>);
    }) : null;
    
    return (<SettingsMasterScreen selection={"feedback"} title={"Feedback"}>
        <div className={"pad constrain feedback"}>
            <h2>Feedback Details</h2>
            <div>
                <p className={"user-content details"}>
                    <HtmlContent>{feedbackItem?.message}</HtmlContent>
                </p>
                
                <div className={"wrap-grid media-grid"}>{mediaElements}</div>
                
                <div className={"message-item-footer"}>
                    <span className={"profile-photo"}>{profileImage}</span>
                    <span className={"name"}><Link to={"/people/" + user?.id}>{feedbackItem?.user?.name || "No Name"}</Link></span>
                    <span>{feedbackItem?.deviceTypeName} version <strong>{feedbackItem?.version}</strong></span>
                    <span><DateTime value={feedbackItem?.created} time={true} /></span>
                </div>

                {repliesHeader}
                
                <div className={"thread"}>{elements}</div>
                
                <div className={"reply-to-form form"}>
                    <div style={{paddingBottom: "8px"}}>Reply to {user?.firstName}:</div>
                    <div><textarea ref={messageRef}></textarea></div>
                    <div className={"form-buttons flex"}>
                        <span><a className={"delete"} onClick={deleteAsync}>Archive</a></span>
                        <DefButton id={buttonId} onClick={saveReplyAsync}>Send Reply</DefButton>
                    </div>
                </div>
                
                <MediaGallery title={"Media Items"} collectionId={feedbackId} entityType={FeedbackModel.entityType} />
                
                <div className={"top32"}>
                    <pre>{JSON.stringify(feedbackItem, null, 4)}</pre>
                </div>
            </div>
        </div>
    </SettingsMasterScreen>);
};

export default FeedbackDetailsScreen;
