import React, {useEffect, useState} from 'react';
import WorkoutsMasterScreen from "./WorkoutsMasterScreen";
import WorkoutService from "../../components/workouts/services/WorkoutService";
import SearchFilter from "../../components/common/ui/search-filter/SearchFilter";
import Initials from "../../components/common/ui/Initials";
import {Link} from "react-router-dom";
import DateTime from "../../components/common/ui/DateTime";
import DefReset from "../../components/common/models/DefReset";
import MediaService from "../../components/media/services/MediaService";
import AddNewButton from "../../components/common/ui/AddNewButton";
import {faDumbbell} from "@fortawesome/free-solid-svg-icons";
import ReactModal from "../../components/common/ui/react-modal/ReactModal";
import WorkoutForm from "../../components/workouts/ui/WorkoutForm";

const WorkoutsScreen = (props) => {
    const {} = props;
    const [screenState, setScreenState] = useState({ workouts: WorkoutService.instance.workouts || [], id: 0, lastCount: -1});
    const workouts = screenState.workouts;
    
    const getWorkoutsAsync = async (force = false) => {
        if (screenState.workouts?.length > 1 && !force) return screenState.workouts;
        
        const options = {};
        const workouts = await WorkoutService.instance.getWorkoutsAsync(options);
        console.log("Got Workouts: ", workouts?.length);
        
        setScreenState({ id: new Date().getTime(), workouts: workouts, lastCount: workouts?.length || 0 });
        
        return workouts;
    }

    const onDateChanged = async (startDate, endDate) => {
        //
    };

    const onDateClicked = async (startDate, endDate) => {
        //
    };

    const showImage = (e, imageUrl) => {
        DefReset.stopEvent(e);
        MediaService.instance.showImage(imageUrl);
    };

    const onSearchType = (e, items) => {
        console.log("Filter Type: ", e);
        console.log("Item Count: ", items?.length);

        if (screenState.lastCount !== items?.length)
            setScreenState({ id: new Date().getTime(), workouts: items, lastCount: items?.length });
    }

    const onNewWorkoutChange = (json, e) => {
        console.warn("On Workout Change");
        console.log(json);
    };
    
    const showNewWorkoutFormAsync = async (e) => {
        DefReset.stopEvent(e);
        const dialog = new ReactModal();
        const workoutOptions = { status: "0" };
        const options = {
            buttons: [
                (<span style={{marginRight: "auto"}}>
                    <select defaultValue={workoutOptions.status} id={"new-workout-status"} onChange={(e) => {
                        workoutOptions.status = e.target.value;
                    }}>
                        <option value={"0"}>Draft</option>
                        <option value={"1"}>Published</option>
                    </select>
                </span>)
            ],
            appendButtons: true,
        };

        const form = (<WorkoutForm options={workoutOptions} onChange={onNewWorkoutChange} id={"00-00"} title={"New Workout"} />);

        const rsp = await dialog.openFormDialogAsync(form, async(e) => {
            DefReset.stopEvent(e);
            const json = workoutOptions.getData();
            json.status = parseInt(workoutOptions.status) || json.status || 0;
            json.parent_workout_id = null;

            const rsp = await WorkoutService.instance.saveWorkoutAsync(json).catch((ex) => {
                if (!!ex?.error) ex = ex.error;
                const message = ex?.response?.data?.message || ex?.message || ex?.toString() || "Unknown Error";
                return null;
            });
            
            return !!rsp?.id;
        }, options);
    };
    
    const onSearchAsync = async (term) => {
        //console.log("Search Term: ", term);
    }

    useEffect(() => {
        let _ = getWorkoutsAsync();
    }, []);

    const elements = screenState.workouts?.map((workout, index) => {
        const link = "" + workout.id;
        const imageUrl = workout?.image?.url || workout?.imageUrl || null;
        const profileImage = !!imageUrl ? (<a onClick={(e) => showImage(e, imageUrl)}><img alt={"Profile Photo"} className={"profile size24"} src={imageUrl} /></a>) :
            (<Initials size={24}>{workout?.name}</Initials>)

        return (<tr key={"person-" + index}>
            <td>{profileImage}</td>
            <td><Link to={link}>{workout.name}</Link></td>
            <td><DateTime date={workout.modified || workout.created} time={true} /></td>
        </tr>);
    });
    
    return (<WorkoutsMasterScreen>
        <div className={"pad"}>
            <h2 className={"nav with-button"}>
                <span>Mobile App Workouts</span>
                <AddNewButton onClick={showNewWorkoutFormAsync} icon={faDumbbell}>Add Workout</AddNewButton>
            </h2>
            <div className={"flex-panel bottom16"}>
                <div><SearchFilter items={WorkoutService.instance.workouts} onChange={(e, items) => onSearchType(e, items)} onClick={onSearchAsync} /></div>
                <div style={{flex: "4"}}></div>
            </div>
    
            <div className={"metrics"}>
                <table className={"table thumb date"}>
                    <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {elements}
                    </tbody>
                </table>
            </div>
        </div>
        </WorkoutsMasterScreen>);
};

export default WorkoutsScreen;
